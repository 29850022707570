import "htmx.org/dist/htmx";
import "bootstrap/dist/js/bootstrap.esm";
import "bootstrap/dist/css/bootstrap.css";
export {
	setupIntegerRangeSlider,
	setupIntegerMaxSlider,
	setupIntegerMinSlider,
	setupIntegerExactSlider,
} from "../range-slider";
import "../style.css";

document.body.addEventListener("htmx:beforeOnLoad", function (evt) {
	if (evt.detail.xhr.status === 418) {
		evt.detail.shouldSwap = true;
		evt.detail.isError = false;
	}
});
